@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.about-bg {
    z-index: -1;
    position: fixed;
    height: 100vh;
    width: 100vw;

    background-image: url(../../Assets/AdobeStock_Brooklyn.jpg);
        background-position: top;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
}

.about-photo {
    position: fixed;
    top: 20vh;
    left: 0vw;
    width: 30vw;
    border: solid black 1px;
    
    animation: shrink 4s;
    transition: ease 1s;
}

.about-blurb {
    margin-left: 30vw;
    width: 70vw;
    height: fit-content;

    padding-top: 10vh;
    background-color: rgba(0, 0, 0, 0.6);
    
    color: antiquewhite;
    font-family: 'Forum', cursive;
    line-height: 1.5;
    font-size: 26px;
    font-weight: 300;
}

.about-blurb p {
    margin: 0;
    margin-left: 5%;
    width: 90%;
}

.about-blurb::first-line {
    font-family: 'Gruppo', serif;
    font-size: 30px;
    font-weight: 800;
}

#invisible {
    transform: translateX(200%);
    transition: ease 1s;
}
