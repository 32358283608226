@media(max-width: 500px){
    #coaching-blurb {
        z-index: 0;
        padding: 10vh 5vw;
        height: 100vh;
        width: 90vw;
        position: relative;
        color: antiquewhite;
        font-size: 16px;
    }

    .coaching-deco-box p {
        display: flex;
        flex-direction: column;
        font-size: 20px;
    }


    .testimonial {
        position: relative;
        padding: 3vh 0vw;
        top: 0;
        left: 0;
        height: fit-content;
        width: 100vw;
    }

        .testimonial p {
            margin-left: 5vw;
            margin-bottom: 0;
            width: 90vw;
            font-size: 18px;
            font-family: 'Forum', sans-serif;
        }

        .testimonial h4 {
            margin-top: 0;
        }

        .testimonial h6 {
            margin-bottom: 6vh;
        }


        #testimonial-button {
            position: fixed;
            left: 70vw;
            bottom: 12vh;
            height: 40px;
            width: 80px;
    
            padding: 0.1em;
            border: none;
            font-family: 'Playfair Display', serif;
            font-size: 30px;
        }

        #testimonial-toggle-button {
            padding: 0.1em;
            position: fixed;
            bottom: 10vh;
            left: 50vw;
            height: 40px;
                width: fit-content;
                background-color: antiquewhite;
                border: none;
                font-family: 'Playfair Display', serif;
                font-size: 30px;
        }


#coaching-certification {
    bottom: 10vh;
    margin: 0;
    left: 75vw;
    width: 20vw;
    opacity: 0.6;
}
}