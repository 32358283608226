
.contact {
	height: 100vh;
	overflow: auto;
}

.contact-block {
	z-index: 1;
	height: 56vh;
	width: 2vw;
	background-color: antiquewhite;
}

.contact-filter {
	margin-top: -25vh;
	position: fixed;
	height: 125vh;
	width: 100vw;
}

.contact-photo {
	z-index: 2;
	position: fixed;
	right: 15vw;
	top: 25vh;
	width: 25vw;
	border-radius: 100%;
	box-shadow: antiquewhite 15px -5px 1px;
}

.contact-box {
	background-size: cover;
	height: 85vh;
	max-width: 400px;

	display: flex;
	flex-direction: column;
	justify-content: end;
}
.contact-headline {
	text-align: right;
	color: black;
	z-index: 1;
	font-size: 62px;
	font-weight: 600;

	font-family: 'Forum', cursive;
	display: none;
}

.contact-blurb {
	z-index: 1;
	color: #faebd7;
	font-family: 'Forum',
		serif;
		
	position: fixed;
	left: 35vw;
	bottom: -15vh;
	height: 35vh;
	max-width: 25vw;
	font-size: 28px;
}

.contact-form {
	height: fit-content;
	width: 30vw;
	margin-left: 15vw;
	padding: 1em;
	background-color: white;
	border-radius: 1rem;

	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
				
}

.input {
	height: 40px;
	width: 80%;
	border: 0.1em solid gray;
}

.label {
	font-size: large;
	line-height: 1.5;
	color: black;
	font-family: 'Forum', serif;
}
.message {
	height: 10vh;
	width: 80%;
}


.contact-button {
	margin: 1em 1vw;
	height: 60px;
	width: 200px;
	font-family: 'Forum', serif;
	border: none;
	border-radius: 0.5em;

	padding: 0.5em;
		text-decoration: none;
		background-color: peachpuff;
		color: black;
}

@media(min-width: 1100px) {
	.contact {
		display: flex;
	}


	.contact-headline {
		color: black;
		z-index: 1;
		font-size: 62px;
		font-weight: 800;
		position: fixed;
		left: 5vw;
		top: 10vh;
	}

	.input {
		height: 30px;
		width: 60%;
		border-radius: 0.5em;
	}
}

@keyframes expand {
	from {
		transform: scale(0.8);
	}

	to {
		transform: scale(1);
	}
	
}