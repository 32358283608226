@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.home-box {
	height: 100vh;
	width: 100vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#home-headline-box {
	background-size: cover;
	background-attachment: fixed;
	
	background-position: center;
	background-repeat: no-repeat;
}

#home-mission-box {
	height: 100vh;
	width: 100vw;

	display: flex;
	flex-direction: column;
}

#home-mission-copy {
	width: 40%;
	position: absolute;
	margin: 0;
	right: 10vw;
	 color: antiquewhite; 
	transition: ease;
	animation: home-slide-up 2.5s;

	font-size: 30px;

	font-family: 'Forum', serif;
}

#lg-management {
	animation: home-slide-up 2.5s;
	font-family: 'Forum', serif;

}

.home-photo {
	width: 25vw;
	position: absolute;

	transition: ease;
	animation: home-float-up 3.5s;

		left: 5vw;
		border: solid black 2px;
		border-radius: 3%;
	
}

#about {
	background-color: peachpuff;
	padding: 0.5em;
	color: black;
	text-align: center;
	font-family: 'Forum', serif;
	font-size: 26px;
}

#scroll-down {
	position: fixed;
	top: 80vh;
	left: 5vw;

	width: 6vw;
	height: 5vw;

	text-align: center;

	animation: bounce 2s infinite;
}


#home-links-box {
	background-attachment: fixed;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.home-filter {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#home-talent-box {
	position: absolute;
	left: 5vw;
	width: 40vw;
}

#home-coaching-box {
	position: absolute;
	right: 1vw;
	width: 40vw;
}


#home-talent-copy,
#home-coaching-copy {
	font-size: 30px;
	margin-bottom: 20px;
	height: fit-content;
	color: antiquewhite;
	font-family: 'Forum';
}

.headline {
	font-size: 80px;
}

#home-talent-link,
#home-coaching-link {
	padding: 0.5em;
	text-decoration: none;
	background-color: peachpuff;
	color: black;
}




#home-talent span,
#home-coaching span {
	font-size: 28px;
	/* line-height: 1.5; */
	border-bottom: solid black 1px;
}

#home-contact-copy {
	position: fixed;
	left: 10vw;
	top: 80vh;
}

#home-contact-copy span {
	text-decoration: none;
}

.homePage-link {
	width: fit-content;
	padding: 0.1em;
	border-radius: 0.5em;

	font-size: 20px;
	color: black;
	text-decoration: none;
	font-family: 'Forum', serif;
}

.homePage-link:hover {
	transform: scale(1.1);
	transition: ease 0.4s;
}

@keyframes home-appear {
	from {
		opacity: 0;
	}

	40% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes home-slide-down {
	from {
		opacity: 1;
		transform: translate(0, 0);

	}
	60% {
		opacity: 1;
		transform: translate(0, 0);
	}
	to {
		opacity: 0;
		transform: translate(0, 100%);
	}
}

@keyframes home-slide-up {
	from {
		opacity: 0;
		transform: translate(0, 50%);
	}
	
	50%{
		transform: translate(0, -3%);
		opacity: 0.7;
	}

	to {
		opacity: 1;
		transform: translate(0, 0);
	}
}

@keyframes home-float-up {

	from {
			opacity: 0;
			transform: translate(0, 50%);
		}
	
		50% {
			transform: translate(0, -3%);
		opacity: 0.7;
		}
	
		to {
			opacity: 1;
			transform: translate(0, 0);
		}

}

@keyframes bounce {
	from {
		transform: translate(0, 0);
	}

	45% {
		transform: translate(0, 10%);
	}

	50% {
		transform: translate(0, 10%);
	}

	to {
		transform: translate(0, 0);
	}
}
