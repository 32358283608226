body {
    background-color: #ca8966;
}

.api-links-box {
    position: fixed;
    left: 0;
    margin: 0;
    height: 65vh;
    width: fit-content;

    background-color: gray;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.api-link {
    margin: 2vw;
    width: 200px;
    height: 20px;
    background-color: antiquewhite;
    text-decoration: none;
    padding: 1em;
    text-align: center;
    transition: ease 0.3s;
    color: black;
}

.api-link:hover {
    opacity: 0.6;
    background-color: aliceblue;
}

.api-create-button {
    position: fixed;
    top: 88vh;
    left: 1vw;

    width: 220px;
    height: 60px;

    font-size: large;
}


.api-div {
    margin: 10vh 35vw;
    padding: 1em;
    width: 60vw;

    background-color: aliceblue;
    border: solid gray 3px;
    border-radius: 1em;
    
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.api-div-buttons {
    margin: 2vh auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.api-div-buttons button {
    width: 150px;
    height: 50px;
}

.api-div p {
    margin: 0 auto;
    max-width: 80%;
}

.api-form {
    margin: 10vh 35vw;
    padding: 2em;

    width: 40vw;
    background-color: gray;


    display: flex;
    flex-direction: column;
    justify-content: center;
}

.api-form input, textarea {
    margin: 2vh 0;
    max-width: 80%;
    min-height: 40px;
}

.api-form label {
    color:antiquewhite
}

.api-form button {
    width: 160px;
    height: 60px;
}