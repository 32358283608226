@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');


.coaching-bg {
    z-index: -2;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-image: url(../../Assets/AdobeStock_LightBulbs.jpg);
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.coaching-filter {
    z-index: -1;
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100vw;
}

#coaching-blurb {
    height: fit-content;
    z-index: 1;

    padding: 5vh 10vw 0;

    line-height: 1.6;
    color: antiquewhite;
    font-size: 22px;
    font-family: 'Forum', sans-serif ;
}

#coaching-blurb::first-line {
    font-family: 'Forum', serif;
    font-size: 32px;
}

.coaching-deco-box p {
    width: 100vw;
    margin: 0;
}

.testimonials-box {
    padding-top: 10vh;
}

.testimonial {
    padding: 5vh 5vw;
    height: fit-content;
    color: antiquewhite;
    transition: ease;
}

.testimonial p {
    width: 90%;
}

.testimonial::-webkit-scrollbar {
    display: none;
}

.testimonial::first-line {
    font-weight: 800;
}

.testimonial p, .testimonial h4, .testimonial h6 {
    color: antiquewhite;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.6;
    font-family: 'Forum', sans-serif;
    margin: 0;
}


.testimonial h4, .testimonial h6 {
    margin-left: 5vw;
    font-weight: 800;
}

.testimonial h6 {
    transition: ease 1.35s;
}

.testimonial h4 {
    font-size: 26px;
    transition: ease 1.25s;
}

.testimonial p {
    margin-bottom: 3vh;
    transition: ease 1.2s;
}

.coaching-deco-box{
    font-family: 'Gruppo', cursive;
    display: flex;;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
}

.coaching-deco-box p {
    font-size: 32px;
}

#coaching-certification {
    z-index: 1;
    bottom: 10vh;
    margin-left: 15vw;
    width: 15vw;
    opacity: 0.8;
}


.dissolvedown{
    transform: translateY(50%);
    opacity: 0;
}

.dissolveup{
    transform: translateY(-50%);
    opacity: 0;
}

.appearup {
    opacity: 1;
}

.appeardown {
    opacity: 1;
}
