.lookbook {
	position: fixed;
	z-index: 2;
	left: 5vw;
	top: 10vh;

	height: 50vh;
	width: fit-content;

	display: grid;
	gap: 1em;
	padding: 1em;
	grid-template-rows: repeat(3, minmax(80px, 1fr));
	grid-template-columns: repeat(2, minmax(100px, 1fr));

	animation: expand 4s;
}

.thumbnail {
	align-self: center;
	justify-self: center;
	width: 70px;
	border: 0.2em solid white;

	transition: ease-in-out 400ms;
}

.lookbook:hover > .thumbnail:hover {
	transform: scale(1.2, 1.2);
	opacity: 1;
	z-index: 3;
}

@media (min-width: 800px) {
	.lookbook {
		position: fixed;
		top: 25vh;
		left: 2vw;

		height: 50vh;
		justify-content: center;

		display: grid;
		gap: 1em;
		padding: 1em;
		grid-template-rows: repeat(2, minmax(80px, 1fr));
		grid-template-columns: repeat(2, minmax(100px, 1fr));
	}
}

@media (min-width: 1100px) {
	.lookbook-title {
		display: flex;
	}
	.lookbook:hover > .lookbook-title {
		display: none;
	}

	.thumbnail {
		margin-top: 0;
		width: 80px;

		transition: ease-in-out 400ms;
	}
}
