.api-blog-container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 5%;
}

.api-blog-form {
    background-color: aliceblue;
    width: fit-content;
    display: flex;
    flex-direction: column;
    margin-top: 5vh;
    margin-left: 35vw;
    padding: 1vw;

    justify-content: center;
}

.api-blog-input {
    width: 400px;
}

.api-blog-button-group {
    width: 400px;
    display: inline;
}