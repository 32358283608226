#hidden {
	left: 100vw;
	transition: ease 1s;
}

#visible {
	left: 0;
	transition: ease 1s;
}

.phone-nav-header {
	z-index: 3;
	position: fixed;
		height: 10vh;
		width: 100vw;
	
		display: flex;
		flex-direction: row;
		align-items: start;
}

.hamburger {
	width: 50px;
	height: 5px;
	border-radius: 1rem;
	margin: 13px;

	background-color: rgb(238, 206, 164);
}

#hamburger {
	z-index: 1;
	position: fixed;
		top: 1vh;
		right: 3vw;
}

.spacer {
	width: 90%;
}

#exit {
	position: fixed;
	z-index: 3;
	top: 1vh;
	right: 3vw;
	border: none;
	border-radius: 100%;
	height: 10vh;
	width: 10vh;

	background-color: antiquewhite;
	background-image: url(https://i.imgur.com/e9P28SI.png);
	background-size: cover;
}

.nav-screen {
	z-index: 2;
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: antiquewhite;
}

.phone-link {
	margin: 0;
	height: 70px;
	width: 100px;

	text-align: center;
	text-decoration: none;
	font-size: 20px;
	color: black;
	font-family: 'Forum', serif;
}

.link:hover {
	color: #c9a227;
}

.home-link {
	margin-top: 0vh;
	height: 7vh;
	width: 9vh;
	align-self: center;
	justify-self: center;
}

@keyframes slide-left {
	from {
		left: 100vw;
	}

	to {
		width: 100vw;
	}
}

@keyframes slide-right {
	from {
		left: 0;
	}

	to {
		left: 100vw;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes rollout {
	from {
		transform: rotate(0);
	}

	50% {
		transform: translate(150px) rotate(180deg);
	}

	to {
		transform: translate(150px) rotate(180deg);
	}
}
