@media(max-width: 500px){
    
.talent-box {
        padding-top: 0;
        height: fit-content;
        width: 100vw;

        display: flex;
        flex-direction: column;
        justify-content: end;



        background-image: url(../../Assets/AdobeStock_theater_seats.jpg);
        background-position: top;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .talent-box::-webkit-scrollbar {
        display: none;
    }

    .actor {
        /* height: 85vh; */
    }

    .headshot {
        grid-column: 2;
        justify-self: center;
        width: 300px;
    }

    .a-tag {
        grid-column: 2;
    }
}