@media(max-width: 500px){
        .about-photo {
         display: none;
        }
    
        .about-blurb {
            margin-left: 0;
            width: 100vw;
            height: fit-content;

            padding-top: 10vh;
            background-color: rgba(0, 0, 0, 0.6);

            color: antiquewhite;
            font-family: 'Forum', cursive;
            line-height: 1.5;
            font-size: 26px;
            font-weight: 300;
        }

        .about-blurb::first-line {
            font-family: 'Forum', serif;
        }
    
        .about-link {
            padding: 0.1em 0.5em;
            text-align: center;

            text-decoration: none;
            text-decoration-line: none;
            color: black;
            font-size: 30px;
            font-family: 'Forum', sans-serif;
        }

        .about-link:hover {
            transform: scale(1);
        }

        #invisible {
            transform: translateX(150%);
            transition: ease 1s;
        }
}