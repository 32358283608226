/* @import-normalize; */
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Forum&display=swap');

.App {
	background-color: rgba(0, 0, 0, 0.6);
}

.App-bg {
	z-index: -3;
	height: 100vh;
	width: 100vw;
	position: fixed;
	background: url(./Assets/AdobeStock_theater_seats.jpg);
		background-size: cover;
		background-attachment: fixed;
	
		background-position: center;
		background-repeat: no-repeat;
}

#designer-links {
	display: flex;
	width: 10vw;
	justify-content: space-around;
}