@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.talent-box {
	margin-top: -25vh;
	padding-top: 25vh;
	height: fit-content;
	width: 100vw;

	display: flex;
	flex-direction: column;
	justify-content: end;



	background-image: url(../../Assets/AdobeStock_theater_seats.jpg);
	background-position:top ;
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
}

.talent-box::-webkit-scrollbar {
	display: none;
}

.headshot {
	grid-column: 2;
	justify-self: center;
	width: 300px;
}

.a-tag {
	grid-column: 2;
}

.loading-page {
	height: 100vh;
	justify-content: center;
}

#loading-message{
	width: 25vw;
	margin: 20vh auto;
	color: antiquewhite;
	font-size: 50px;
	font-family: 'Forum', cursive;
}