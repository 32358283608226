@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');

.actor {
	margin-top: 5vh;
}

.actor-box {
	margin-bottom: 5vh;
	margin-left: 5vw;
	height: 85vh;
	width: 100vw;
	background-size: cover;

	display: grid;
		grid-template-columns: 1fr 2fr 2fr;
		grid-template-rows: 1fr;
}

.actor-name {
	margin: 0;
	grid-row: 1;
	grid-column: 1;
	margin-left: 1em;
	font-size: 40px;
	align-self: start;
	color: whitesmoke;
	border-bottom: black solid 1px;
	font-family: 'Forum',
	serif;
}

#actor-button {
	display: none;
}
.actor-photo {
	height: 85vh;
	grid-column: 3;
}

.actor-bio {

	grid-row: 1;
		grid-column: 2;

	height: 85vh;

	line-height: 1.5;
	z-index: 1;
	margin: 0 auto;
	padding: 0 0.7em ;

	color: whitesmoke;
	background-color: rgba(0,0,0,0.7);
	animation: fadein 5s;
	font-family: 'Forum', sans-serif;
	font-size: large;
}

.actor-a-tag {
	line-height: 1.5;
	height: 50px;
	align-self: center;
}

.actor-a-tag:hover {
	transform: scale(1.2);
	transition: ease 0.4s;
}

@media (min-width: 1100px) {
	/* .actor {
		height: 70vh;

		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
	} */

	.empty {
		height: 0;
	}	
}

@keyframes actor-slide-up {
	from {
		transform: translate(0,100%);
	}
	to {
		transform: translate(0, 0);
	}
}


@keyframes actor-slide-out {
	from {
		transform: translate(0, 0);
	}

	to {
		transform: translate(0, -150%);
	}
	
}
