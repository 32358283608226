.Nav {
	height: 20vh;
	width: 100vw;

	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: black;
}

.link {
	z-index: 2;
	width: 100px;
	background-size: 0% 0.1em;
	text-decoration: none;
	text-align: center;
	transition:  0.2s ease-in-out;

	font-size: x-large;
	color: antiquewhite;

	font-family: 'Forum', serif;
}

.link:hover, .link:focus, .link:active {
	color: antiquewhite;
	background-size: 100% 0.1em;
}

.home-link {
	margin-top: 2vh;
	height: 15vh;
	width: 21vh;
	padding: 0;
}
