
.blog-post {
    height: fit-content;
    margin: 5vh 5vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

    padding: 5vw;

    background-color: antiquewhite;
    opacity: 0.6;
}

.blog-title {
    font-size: 30px;
    font-family: 'Forum', serif;
}