@media(max-width: 1099px){

    .contact {
        padding: 10vh;
        height: 100vh;
    }

    .contact-form {
        height: fit-content;
        top: 1vh;
        left: 0vw;
        position: fixed;
        width: 92vw;
        padding-top: 5vh;
        padding-bottom: 10vh;
        background-color: rgba(255, 255, 255, 0);

        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
    }

        .label {
            color: black;
            margin: 1vh;
            font-size: 18px;
        }
        .input {
            margin: 0;
        }
    
        .contact-button {
            font-size: 20px;
            width: 100px;
            font-family: 'Forum', Courier, monospace;
            margin-top: 1vh;    
            box-shadow: 1px 1px 1px black;        
        }
    

        .contact-photo {
            z-index: 0;
                position: fixed;
                left: 55vw;
                top: 65vh;
                height: 35vw;
                width: 35vw;
                border: 1px solid black;
                box-shadow: none;
        }
    
        .contact-box,
        .contact-block,
        .contact-filter{
            display: none;
        }
}