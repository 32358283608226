@media (max-width: 1099px ) {   
    .actor-box {
            height: 100vh;
            width: 100vw;
            background-color: rgba(255, 255, 255, 0.2);
        }

    .actor {
        margin: 0;
        height: 100vh;
        width: 100vw;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: local;

        display: flex;
        flex-direction: column;

        align-items: end;
    }

    .actor-thumbnail {
        height: 200px;
        float: left;
        border: solid white 10px;
        margin-right: 5vw;
    }

    .actor-name-phone-box {
        height: 20vh;
        width: 100vw;
        display: flex;
        flex-direction: row;
        background-color: rgba(0, 0, 0, 0.8);

        align-self: end;
        align-items: center;
        justify-content: start;
    }

    .actor-name {
        margin: 0;
        margin-top: 0;
        margin-left: 5vw;
        width: 40vw;

        align-self: center;
        font-size: 20px;
    }
    .actor-button {
        margin-left: 0;
        width: 40vw;
        border: none;
        padding: 0.5em;
        background-color: antiquewhite;

        border-radius: 0.3rem;

        color: black;
        font-family: 'Forum', sans-serif;
        font-size: 16px;
    }

    .actor-bio-copy{
        transform: translateX(0);
        margin: 0;
        background-color: rgba(0, 0, 0, 1);
        height: 100%;
        width: 100vw;

        align-items: end;
        transition: ease;
        color: white;

        display: flex;
        flex-direction: column;
        transition: ease 1s;
    }

    .actor-bio-copy p {
        padding: 3vw;
        font-size: 18px;
        text-align: left;
    }
    .actor-photo {
        max-width: 100vw;
    }

    @keyframes slide-up {
        from {
            transform: translateY(0);
        }

        to {
            transform: translateY(200%);
        }
    }   
}