@media (max-width: 1099px) {



#home-headline-box {
    background-attachment: local;
}

#home-headline {
    margin-top: 0;
	width: 70vw;
    height: fit-content;
    justify-self: center;
}

.home-filter {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        display: none;
    }

    #home-mission-box {
        align-self: center;
        justify-self: center;
        padding-top: 0px;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 90vw;
    }

    #home-mission-filter {
       background-color: rgba(0, 0, 0, 0.5);
       margin-top: 50vh;
       width: 100vw;
       height: 65vh;
       padding: 0.1em;
       align-items: start;
    }

    #lg-management {
        font-size: 40px;
    }

    #home-mission-copy {
        position: relative;
        margin: 0;
        margin-left: 5vw;
        justify-self: end;
        right: 0;
        padding: 0.1em;
        text-align: justify;
        font-size: 30px;
        height: 50vh;
        width: 85vw;
        color: antiquewhite;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #about {
        margin-left: 5vw;
    }

    .homePage-link {
        width: fit-content;
        padding: 0.1em;

        font-size: 20px;
        color: black;
        text-decoration: none;
        font-family: 'Forum', serif;
    }

        #home-links-box {
            height: 100vh;
            background-attachment:local;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }


    #home-talent-box, #home-coaching-box{
        margin: -0.01em;
            position: relative;
            height: 50vh;
            width: 100vw;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.7);
            right: 0vw;
            left: 0vw;
        }

    #home-talent-copy,
        #home-coaching-copy {
            padding: 1em;
            padding-top: 3em;
            font-size: 20px;
            height: fit-content;
            color: antiquewhite;
        }
    
        .headline {
            font-size: 30px;
        }
    
        #home-talent-link,
        #home-coaching-link {
            margin-left: 5vw;
            line-height: 2.5;
            padding: 0.5em;
            text-decoration: none;
            /* background-color: #3a38bb; */
            /* color: aliceblue; */
        }
    
}